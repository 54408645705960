import {BrowserRouter, Routes, Route, Link} from 'react-router-dom';
import '@fontsource/roboto/400.css';
import './App.css';
import './bootstrap-grid.min.css';
import Login from './components/Login';

function App() {
  return (
    <div>
        <BrowserRouter>
          <Routes>
            <Route index element={<Login />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;